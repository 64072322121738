import BackButton from 'components/BackButton';
import Button from 'components/Button';
import SkipButton from 'components/SkipButton';

import styles from './styles.module.scss';

const ModalFooter = ({
    backButtonClicked,
    backButtonDisabled,
    backButtonLabel,
    footerButtonClassName,
    footerContainerClassName = styles.footerContainer,
    footerButtonClicked,
    footerButtonDisabled,
    footerClassName,
    footerId = 'modelFooter',
    footerLabel,
    footerSubContent,
    isBack,
    isFooterButtonEnable,
    isSkip,
    skipButtonClassName,
    skipButtonClicked,
    skipButtonDisabled,
    skipButtonLabel,
    closeButtonClicked,
    isCloseButton,
    isClearButton,
    clearButtonClicked
}) =>
    isSkip || isBack ? (
        <div
            className={`${footerClassName} ${styles.footerSkipBack}  ${
                !isBack && styles.backSkipBtnsEnd
            }
            }  `}
        >
            <div
                className={` ${styles.backSkipBtns} ${
                    ['View Plans', 'View Coverage'].includes(skipButtonLabel)
                        ? styles.backSkipBtnsCenter
                        : !isBack || !isSkip
                          ? styles.backSkipBtnsEnd
                          : ''
                }`}
            >
                {isBack && (
                    <BackButton
                        disabled={backButtonDisabled}
                        onClick={backButtonClicked}
                        label={backButtonLabel}
                        id={`${backButtonLabel?.replaceAll(' ', '_')}Button`}
                        isBack={isBack}
                    />
                )}

                <div
                    className={` ${
                        footerSubContent && styles.footerViewPlans
                    }  ${
                        skipButtonLabel === 'Skip to Plans' &&
                        styles.footerSkipAlign
                    } ${styles.footerSkip}`}
                >
                    {isSkip && (
                        <>
                            {footerSubContent && (
                                <p id={'footerSubContent'}>
                                    {footerSubContent}
                                </p>
                            )}
                            <SkipButton
                                className={`${skipButtonClassName} ${styles.skipButton}`}
                                disabled={skipButtonDisabled}
                                footerSubContent={footerSubContent}
                                label={skipButtonLabel}
                                onClick={skipButtonClicked}
                                id={`${skipButtonLabel?.replaceAll(
                                    ' ',
                                    '_'
                                )}Button`}
                            />
                        </>
                    )}
                </div>
            </div>
            <div className={footerContainerClassName}>
                {isFooterButtonEnable && (
                    <Button
                        className={`${footerButtonClassName} ${
                            styles.footerButtonClassName
                        } ${isSkip && styles.footerButtonfullWidth}`}
                        disabled={footerButtonDisabled}
                        label={footerLabel}
                        onClick={footerButtonClicked}
                        id={`${footerLabel?.replaceAll(' ', '_')}Button`}
                    />
                )}
            </div>
        </div>
    ) : (
        <div className={`${footerClassName} ${styles.footer}`}>
            {isCloseButton && (
                <Button
                    className={`${styles.closeButtonClassName}`}
                    label="Close"
                    onClick={closeButtonClicked}
                />
            )}
            {isClearButton && (
                <Button
                    className={styles.closeButtonClassName}
                    onClick={clearButtonClicked}
                    label={'Clear Filter'}
                />
            )}
            <Button
                className={`${footerButtonClassName} ${
                    styles.footerButtonClassName
                } ${(isSkip || isBack) && styles.footerButtonfullWidth}`}
                disabled={footerButtonDisabled}
                id={`${footerLabel?.replaceAll(' ', '_')}Button`}
                label={footerLabel}
                onClick={footerButtonClicked}
            />
        </div>
    );

export default ModalFooter;
