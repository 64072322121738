import Label from 'components/Label';
import Radio from 'components/Radio';

import styles from './styles.module.scss';

const RadioWithLabel = ({
    checked = false,
    className = '',
    disabled = false,
    id = '',
    label = '',
    name = '',
    onChange,
    value = '',
    radioClassName = ''
}) => (
    <div
        className={`${className} ${styles.radioWithLabel}`}
        onClick={() => name === 'Health_Condition' && onChange(value)}
    >
        <Radio
            id={id}
            disabled={disabled}
            name={name}
            value={value}
            onChange={onChange}
            isChecked={checked}
            className={radioClassName}
        />

        <Label
            htmlFor={id}
            className={styles.labelClass}
            text={label}
            id={`${id}_label`}
        />
    </div>
);

export default RadioWithLabel;
