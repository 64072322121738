import moment from 'moment';
import React, { forwardRef, useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { useAuth0 } from '@auth0/auth0-react';

import Body2 from 'components/Body2';
import DatePickerWithLabel from 'components/DatePickerWithLabel';
import Text from 'components/Text';
import { setHealthConditionContentModal } from 'components/YourHealthConditionContentModal/slice';
import {
    setHealthConditionsList,
    setHealthIntakeModal
} from 'components/YourHealthIntakeModal/slice';

import {
    HEALTH_CONDITION_DETAILS_KEY,
    HEALTH_CONDITION_INDEX_KEY,
    HEALTH_CONDITION_OPERATION_KEY,
    HEALTH_CONDITION_TERM_KEY
} from 'utilities/storageKeys';

import { invalidPatternError } from './constants';

import styles from './styles.module.scss';

import { deleteUserHealthConditions } from 'temp/utilities/apiSession/healthConditions';
import { get, set } from 'temp/utilities/storage';

const YourHealthIntakeModalContent = forwardRef(
    ({ condition, setDateOfLastTreatmentVal, modalClosed, isModal }, _) => {
        localStorage.removeItem('HealthInfoDataChanged');

        const [contactDetailsPage] = useState(false);
        const [isApiCalled, setIsApiCalled] = useState(false);
        const [dateError] = useState(false);

        const dispatch = useDispatch();
        const [lastTreatmentDate, setLastTreatmentDate] = useState('');

        const isFirstLoad = useRef(true);
        const conditionObj = get(HEALTH_CONDITION_TERM_KEY);
        useEffect(() => {
            if (isFirstLoad.current) {
                setLastTreatmentDate(conditionObj?.lastTreatmentDate);
                setDateOfLastTreatmentVal(lastTreatmentDate);
                isFirstLoad.current = false;
            }
        }, [isFirstLoad]);

        useEffect(() => {
            setDateOfLastTreatmentVal(lastTreatmentDate);
        }, [lastTreatmentDate]);
        const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();

        const deleteCondition = async () => {
            const conditionList = get(HEALTH_CONDITION_DETAILS_KEY) || [];
            const conditionObjIndex = get(HEALTH_CONDITION_INDEX_KEY);
            const operation = get(HEALTH_CONDITION_OPERATION_KEY);

            if (operation === 'Edit') {
                if (isAuthenticated && !isApiCalled) {
                    const authToken = await getAccessTokenSilently();
                    setIsApiCalled(true);
                    await deleteUserHealthConditions({
                        consumerId: user?.consumerid,
                        authToken,
                        conditionId: conditionObj.id
                    });
                    conditionList.splice(conditionObjIndex, 1);
                    setIsApiCalled(false);
                    set(HEALTH_CONDITION_DETAILS_KEY, conditionList);
                } else {
                    conditionList.splice(conditionObjIndex, 1);
                    set(HEALTH_CONDITION_DETAILS_KEY, conditionList);
                }
            }

            !isModal && modalClosed(true);
            dispatch(setHealthConditionsList(conditionList));
            dispatch(setHealthIntakeModal(false));
            isModal && dispatch(setHealthConditionContentModal(true));
        };

        return (
            <>
                <div
                    className={`${
                        contactDetailsPage && styles.contactDetailsPage
                    }`}
                >
                    <Text
                        className={styles.contactDetailsText}
                        text={conditionObj?.conditionName || condition}
                        id={`${
                            conditionObj?.conditionName?.replaceAll(' ', '_') ||
                            condition?.replaceAll(' ', '_')
                        }Label`}
                    />

                    {conditionObj?.hasLookBackPeriod && (
                        <div className={styles.dateOfBirth}>
                            <DatePickerWithLabel
                                defaultValue={lastTreatmentDate || ''}
                                id="lastTreatmentDate"
                                labelText="Date of Last Treatment?"
                                onChange={date => {
                                    setLastTreatmentDate(
                                        date ??
                                            moment(date).format('MM/DD/YYYY')
                                    );
                                }}
                                labelClass={styles.text}
                            />

                            <Text className={styles.error} id="error">
                                {dateError && invalidPatternError}
                            </Text>
                        </div>
                    )}

                    <div className={styles.conditionDelete}>
                        <Body2
                            className={styles.deleteCondition}
                            text={`Delete Condition`}
                            onClick={deleteCondition}
                            id="deleteConditionLink"
                        />
                    </div>
                </div>
            </>
        );
    }
);

export default React.memo(YourHealthIntakeModalContent);
