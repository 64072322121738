import TextFieldWithLabel from 'components/TextFieldWithLabel';

import { errorMessage, errorMessagePattern, emailRegex } from './data';

import styles from './styles.module.scss';

const EmailTextFieldWithLabel = ({
    autoFocus,
    body2ClassName,
    className = '',
    defaultValue,
    disablePattern = false,
    disabled,
    error,
    fieldClassName,
    id,
    labelText,
    labelClassName = styles.label,
    placeholder = 'example@company.com',
    register,
    swiperRef,
    required = true
}) => (
    <div
        className={`${styles.container} ${className}`}
        onClick={() => {
            if (swiperRef?.current?.autoplay) {
                swiperRef?.current?.autoplay?.stop();
            }
        }}
    >
        <TextFieldWithLabel
            autoFocus={autoFocus}
            body2ClassName={body2ClassName}
            defaultValue={defaultValue}
            disabled={disabled}
            error={error}
            errorMessage={errorMessage}
            errorMessagePattern={errorMessagePattern}
            fieldClassName={`${fieldClassName} ${styles.textField}`}
            id={id}
            labelClassName={labelClassName}
            labelText={labelText}
            pattern={disablePattern && emailRegex}
            placeholder={placeholder}
            register={register}
            required={required}
            type="email"
        />
    </div>
);

export default EmailTextFieldWithLabel;
