import PlansByZipForm from '../PlansByZipForm';
import { isFunction } from 'formik';
import React, { useState, useEffect, useRef } from 'react';
import Media from 'react-media';

import { SearchOutlined } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';

import ActionText from 'components/ActionText';
import Heading5 from 'components/Heading5';
import RadioMultipleOptions from 'components/RadioMultipleOptions';
import Text from 'components/Text';

import {
    HEALTH_CONDITION_DETAILS_KEY,
    HEALTH_CONDITION_SEARCH_TERM_KEY,
    HEALTH_CONDITION_TERM_KEY
} from 'utilities/storageKeys';

import {
    SearchCommonConditionList,
    multipleFoundLabel,
    noResultsMessage,
    singleFoundLabel,
    startMessage
} from './constants';

import styles, { mobileWidth } from './styles.module.scss';

import DrugListItem from 'temp/components/DrugListItem';
import FinalExpenseHeaderNav from 'temp/components/FinalExpenseHeaderNav';
import { getHealthConditions } from 'temp/utilities/apiSession/finalExpense';
import { get, set } from 'temp/utilities/storage';

const YourHealthConditionModalContent = ({
    setSearchValue,
    setHasLookBackPeriod
}) => {
    localStorage.removeItem('HealthInfoDataChanged');
    const [isMobile, setIsMobile] = useState(false);
    const [searchVal, setSearchVal] = useState(
        get(HEALTH_CONDITION_SEARCH_TERM_KEY)
    );
    const [condition, setCondition] = useState('');
    const [searchList, setSearchList] = useState();

    const [contactDetailsPage] = useState(false);
    const conditionsList = get(HEALTH_CONDITION_DETAILS_KEY);
    const label =
        searchList?.length === 1 ? singleFoundLabel : multipleFoundLabel;
    const text1 = `${searchList?.length} ${label} `;
    const text = `found`;
    const isFirstLoad = useRef(true);
    const handleClear = () => {
        setSearchVal('');
        setCondition('');
        setSearchValue('');
    };

    useEffect(() => {
        const LoadHealthConditions = async () => {
            if (searchVal?.length > 2) {
                set(HEALTH_CONDITION_SEARCH_TERM_KEY, searchVal);
                const searchListRes = await getHealthConditions(searchVal);
                const filterResult = searchListRes?.uwConditions?.filter(
                    condition =>
                        !conditionsList.some(
                            value =>
                                condition.conditionName === value.conditionName
                        )
                );

                setSearchList(filterResult);
            }
        };
        LoadHealthConditions();
    }, [searchVal]);

    useEffect(() => {
        if (isFirstLoad.current) {
            const conditionObj = get(HEALTH_CONDITION_TERM_KEY);

            setCondition(conditionObj?.conditionName);
            setSearchValue(conditionObj?.conditionName);
            setHasLookBackPeriod(conditionObj?.hasLookBackPeriod);
            setSearchVal(conditionObj?.conditionName);
            isFirstLoad.current = false;
        }
    }, [isFirstLoad]);

    const handleClick = conditionObj => {
        setCondition(conditionObj.conditionName);
        setSearchValue(conditionObj.conditionName);
        setHasLookBackPeriod(conditionObj.hasLookBackPeriod);
        set(HEALTH_CONDITION_TERM_KEY, conditionObj);
    };
    const resultMap = (value, index) => (
        <DrugListItem
            checked={value.conditionName === condition}
            isRadio
            name={value.conditionName}
            onClick={e => handleClick(value)}
            id={value.conditionId}
            key={value.conditionId}
            value={condition}
        />
    );

    return (
        <>
            {contactDetailsPage && (
                <div>
                    <div className={styles.contactDetailsHeaderNav}>
                        <FinalExpenseHeaderNav from={'ContactDetails'} />
                    </div>
                </div>
            )}
            <div
                className={`${contactDetailsPage && styles.contactDetailsPage}`}
            >
                <Media
                    queries={{
                        mobile: { maxWidth: mobileWidth }
                    }}
                    onChange={breakpoint => {
                        setIsMobile(breakpoint.mobile);
                    }}
                />
                {contactDetailsPage && (
                    <Text
                        className={styles.contactDetailsText}
                        text={'Contact Details'}
                        id="contactDetailsLabel"
                    />
                )}

                <div
                    className={`${contactDetailsPage && styles.contactDetails}`}
                >
                    {contactDetailsPage && (
                        <>
                            <Text
                                className={styles.confirm}
                                text={'Let’s confirm some details'}
                                id="confirm"
                            />

                            <Text
                                className={styles.questions}
                                text={
                                    'Just a few quick and easy questions to get your quote.'
                                }
                                id="questions"
                            />

                            <div>
                                <PlansByZipForm
                                    className={styles.zipCodeInput}
                                    id={'ContactDetails'}
                                    zipLabelClass={styles.text}
                                    countyLabelClass={styles.text}
                                    isOnChangeEnable={true}
                                    showSingleCounty={false}
                                />
                            </div>
                        </>
                    )}

                    <div className={styles.gender}>
                        <Text
                            className={styles.text}
                            text={`Common Conditions`}
                            id="commonConditionsLabel"
                        />

                        <RadioMultipleOptions
                            className={styles.radioClassName}
                            id="Health_Condition"
                            items={SearchCommonConditionList}
                            name="Health_Condition"
                            onChange={event => {
                                setSearchVal(event.target?.value || event);
                            }}
                            selectedOption={searchVal}
                            optionsClassName={styles.optionsClassName}
                            radioClassName={styles.radioClass}
                        />
                    </div>

                    <div className={styles.heightAndWeight}>
                        <div>
                            <Text
                                className={styles.text}
                                text={`Or Search Conditions`}
                                id="searchConditions"
                            />
                            <div className={styles.height}>
                                <TextField
                                    className={styles.heightTextField}
                                    onChange={event => {
                                        setSearchVal(event.target.value);
                                    }}
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment
                                                className={
                                                    styles.cancelSearchLabel
                                                }
                                                position="end"
                                            >
                                                {searchVal && (
                                                    <ActionText
                                                        className={styles.link}
                                                        onClick={handleClear}
                                                        id="cancelSearch"
                                                    >
                                                        Cancel
                                                        <span
                                                            className={
                                                                styles.hideOnMobile
                                                            }
                                                        >
                                                            Search
                                                        </span>
                                                    </ActionText>
                                                )}
                                                {(isMobile || searchVal) && (
                                                    <SearchOutlined
                                                        className={
                                                            styles.searchIcon
                                                        }
                                                        id="searchIcon"
                                                    />
                                                )}
                                            </InputAdornment>
                                        )
                                    }}
                                    placeholder={
                                        'Start by typing health condition'
                                    }
                                    value={searchVal || ''}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {searchList ? (
                    <>
                        <div className={styles.foundText} id="foundText">
                            <Heading5
                                className={styles.heading5}
                                text={text1}
                                id={text1?.replaceAll(' ', '_')}
                            />

                            <Text
                                className={` ${styles.textItalic}`}
                                text={text}
                                id={
                                    typeof text === 'string'
                                        ? text?.replaceAll(' ', '_')
                                        : ''
                                }
                            />
                        </div>

                        <ul className={styles.resultsList}>
                            {isFunction(resultMap) &&
                                searchList?.map(resultMap)}
                        </ul>
                    </>
                ) : (
                    <Text
                        className={styles.conditionText}
                        text={`${searchVal ? noResultsMessage : startMessage}`}
                        id="startMessage"
                    />
                )}
            </div>
        </>
    );
};

export default YourHealthConditionModalContent;
