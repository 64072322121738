import TextFieldWithLabel from 'components/TextFieldWithLabel';

import { placeholder, nameRegex, nameError, invalidPatternError } from './data';

import styles from './styles.module.scss';

export const isValidLastName = value => {
    return value && value.length > 1 ? nameRegex.test(value) : false;
};

const LastNameTextField = ({
    autoFocus,
    className = '',
    defaultValue,
    disablePattern = false,
    error,
    id,
    labelText,
    noPlaceholder = false,
    register,
    required,
    labelClassName = styles.label,
    setError = () => {},
    clearErrors = id => {}
}) => {
    const validate = value => {
        if (value && (value.length < 2 || !isValidLastName(value))) {
            setError(id, {
                type: 'pattern'
            });
        } else {
            clearErrors(id);
        }
    };

    const onBlur = e => {
        const { value } = e.target;
        validate(value);
    };
    return (
        <div className={`${className} ${styles.container}`}>
            <TextFieldWithLabel
                autoFocus={autoFocus}
                defaultValue={defaultValue}
                error={error}
                errorMessage={nameError}
                errorMessagePattern={invalidPatternError}
                fieldClassName={styles.textField}
                id={id}
                onBlur={onBlur}
                labelClassName={labelClassName}
                labelText={labelText}
                pattern={disablePattern && nameRegex}
                placeholder={!noPlaceholder && placeholder}
                register={register}
                required={required}
                type="text"
            />
        </div>
    );
};
export default LastNameTextField;
