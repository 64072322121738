import { memo, useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useAuth0 } from '@auth0/auth0-react';

import Modal from 'components/Modal';
import ModalWindow from 'components/ModalWindow';
import { setHealthConditionContentModal } from 'components/YourHealthConditionContentModal/slice';
import YourHealthConditionModalContent from 'components/YourHealthConditionModalContent';
import YourHealthIntakeModal from 'components/YourHealthIntakeModal';
import {
    setHealthConditionsList,
    setHealthIntakeModal
} from 'components/YourHealthIntakeModal/slice';

import dataLayer from 'utilities/dataLayer';
import {
    HEALTH_CONDITION_DETAILS_KEY,
    HEALTH_CONDITION_OPERATION_KEY,
    HEALTH_CONDITION_TERM_KEY
} from 'utilities/storageKeys';

import image from './image.svg';

import { headerTitle, headerText, saveButtonLabel } from './constants';
import { setHealthConditionModal } from './slice';

import styles from './styles.module.scss';

import { createHealthCondition } from 'temp/utilities/apiSession/healthConditions';
import { get, set } from 'temp/utilities/storage';

const YourHealthConditionModal = ({ isModal, modalClosed }) => {
    const [isDisable, setIsDisable] = useState(false);
    const healthConditionModal = useSelector(
        state => state.healthConditionDetails?.healthConditionModal
    );
    const [searchValue, setSearchValue] = useState();
    const [hasLookBackPeriod, setHasLookBackPeriod] = useState();

    useEffect(() => {
        if (searchValue) {
            setIsDisable(false);
        } else setIsDisable(true);
    }, [searchValue]);
    useEffect(() => {
        if (healthConditionModal) {
            dataLayer('modal_appear', headerTitle);
        }
    }, [healthConditionModal]);
    const dispatch = useDispatch();

    const onClose = async isContinue => {
        dispatch(setHealthConditionModal(false));
        isModal && dispatch(setHealthConditionContentModal(true));
    };

    const userProfile = useSelector(state => state.userProfile.userProfile);
    const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
    const openIntakeForm = async () => {
        const operation = get(HEALTH_CONDITION_OPERATION_KEY);

        if (operation !== 'Edit' && !hasLookBackPeriod) {
            const conditionObj = get(HEALTH_CONDITION_TERM_KEY);
            const conditionList = get(HEALTH_CONDITION_DETAILS_KEY) || [];

            conditionList.push(conditionObj);
            if (isAuthenticated) {
                setIsDisable(true);
                const authToken = await getAccessTokenSilently();
                await createHealthCondition({
                    consumerId: user?.consumerid,
                    authToken,
                    stateCode: userProfile?.stateCode,
                    healthConditionData: conditionList
                });
                setIsDisable(false);
            }
            set(HEALTH_CONDITION_DETAILS_KEY, conditionList);
            !isModal && modalClosed(true);
            dispatch(setHealthConditionsList(conditionList));
            isModal && dispatch(setHealthConditionContentModal(true));
        }
        !isModal && modalClosed(true);
        dispatch(setHealthConditionModal(false));
        dispatch(setHealthIntakeModal(hasLookBackPeriod));
    };

    return (
        <div className={styles.modalWithHeaderFooter}>
            <Modal className={styles.modal} isOpen={healthConditionModal}>
                <ModalWindow
                    closeIconClassName={styles.closeIconClassName}
                    contentClassName={styles.contentClassName}
                    footerButtonClassName={styles.footerButtonClassName}
                    footerButtonClicked={() => openIntakeForm()}
                    footerButtonDisabled={isDisable}
                    footerClassName={styles.footerClassName}
                    footerLabel={saveButtonLabel}
                    headerClassName={styles.headerClassName}
                    headerIcon={image}
                    headerText={headerText}
                    headerTitle={headerTitle}
                    modalWindow={styles.modalWindow}
                    onClose={() => onClose()}
                >
                    <div className={styles.content}>
                        <YourHealthConditionModalContent
                            setSearchValue={setSearchValue}
                            setHasLookBackPeriod={setHasLookBackPeriod}
                        />
                    </div>
                </ModalWindow>
            </Modal>
            <YourHealthIntakeModal
                condition={searchValue}
                modalClosed={modalClosed}
                isModal={isModal}
            />
        </div>
    );
};

export default memo(YourHealthConditionModal);
