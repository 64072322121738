import RadioWithLabel from 'components/RadioWithLabel';

import styles from './styles.module.scss';

const RadioMultipleOptions = ({
    className = '',
    disabled = false,
    id = '',
    items = [],
    name = '',
    onChange,
    selectedOption,
    optionsClassName,
    radioClassName
}) => (
    <div className={`${styles.radioMultipleOptions} ${optionsClassName}`}>
        {items.map((value, index) => {
            return (
                <RadioWithLabel
                    checked={selectedOption === value}
                    className={className}
                    disabled={disabled}
                    id={`${id}_${index}`}
                    label={value}
                    name={name}
                    onChange={onChange}
                    value={value}
                    radioClassName={radioClassName}
                />
            );
        })}
    </div>
);

export default RadioMultipleOptions;
