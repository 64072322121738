import { useEffect, useRef } from 'react';

import Heading3 from 'components/Heading3';
import Icon from 'components/Icon';
import Text from 'components/Text';

import healthPlansEmpty from './HealthPlansEmpty.svg';
import healthPlansIcon from './image.svg';
import healthPlus from './healthPlus.svg';

import styles from './styles.module.scss';
import IconWithLabel from 'components/IconWithLabel';

const WalletHealthCard = ({
    mouseOver,
    mouseOverOut,
    cardClassName = styles.walletHealthPlans,
    openHealthPlanModal,
    plansDetailsIndex = 0,
    showAllByDefault,
    plansDetails,
    walletID,
    isVerifyId = false,
    plansDetailsList = []
}) => {
    const ref = useRef();
    const PlanDate = ({ label, data, className = styles.heading }) =>
        data ? (
            <div className={styles.data}>
                <Heading3 className={className} text={`${label}:`} />

                <Text className={styles.policyDetails}>{data}</Text>
            </div>
        ) : null;
    useEffect(() => {
        if (ref.current == null) {
            return;
        }

        document
            .getElementById(`healthIcon-${plansDetailsIndex}`)
            ?.setAttribute(
                'style',
                `background-color:${plansDetails?.carrierColor || '#052A63'}`
            );
    }, [plansDetails?.carrierColor, plansDetailsIndex, ref]);

    return (
        <div
            className={plansDetails ? styles.healthPlans : cardClassName}
            style={{ backgroundColor: plansDetails?.carrierColor || '#052A63' }}
            id={`healthPlansEmpty-${plansDetailsIndex}`}
        >
            {plansDetailsList.length > 0 && isVerifyId ? (
                <div
                    className={styles.healthWalletInfoIcon}
                    onClick={() =>
                        walletID !== `healthPlansEmpty-${plansDetailsIndex}`
                            ? mouseOver(`healthPlansEmpty-${plansDetailsIndex}`)
                            : openHealthPlanModal(
                                  healthPlansIcon,
                                  plansDetailsIndex
                              )
                    }
                    id={`healthInfoIcon-${plansDetailsIndex}`}
                    ref={ref}
                >
                    <div>
                        <IconWithLabel
                            alt="Health Icon"
                            labelClassName={styles.heading}
                            image={healthPlus}
                            caption={`Health Plans (${
                                plansDetailsList?.length
                            })`}
                        />
                    </div>
                </div>
            ) : plansDetails ? (
                <div
                    className={styles.healthInfoIcon}
                    onClick={() =>
                        openHealthPlanModal(healthPlansIcon, plansDetailsIndex)
                    }
                    id={`healthIcon-${plansDetailsIndex}`}
                    ref={ref}
                >
                    {plansDetails?.planName ? (
                        <Text
                            text={plansDetails?.planName}
                            className={styles.planName}
                        />
                    ) : (
                        <div>
                            <Text
                                text={'Health Plan'}
                                className={styles.noPlanName}
                            />
                            <Text text={'Name'} className={styles.nameLabel} />
                        </div>
                    )}
                    <div className={styles.policyDetailsContainer}>
                        <div className={styles.detailsContainer}>
                            <PlanDate
                                label="Status"
                                data={`${
                                    plansDetails?.policyStatus
                                        ? 'Active'
                                        : 'N/A'
                                }`}
                            />
                            <PlanDate
                                className={styles.heading3}
                                label="Policy Number"
                                data={plansDetails?.policyNumber}
                            />
                        </div>
                        {plansDetails?.carrierIconURL && (
                            <Icon
                                className={`${styles.icon}`}
                                image={plansDetails?.carrierIconURL}
                                id="insuranceIcon"
                            />
                        )}
                    </div>
                </div>
            ) : (
                <Icon
                    image={healthPlansEmpty}
                    id={`healthPlansEmpty-${plansDetailsIndex}Icon`}
                    onClick={() =>
                        walletID !== `healthPlansEmpty-${plansDetailsIndex}`
                            ? mouseOver(`healthPlansEmpty-${plansDetailsIndex}`)
                            : openHealthPlanModal(healthPlansEmpty, 0)
                    }
                    className={styles.healthIcon}
                />
            )}
        </div>
    );
};

export default WalletHealthCard;
