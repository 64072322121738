import { memo, useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUserProfile } from 'services/clientServices/slice';

import { useAuth0 } from '@auth0/auth0-react';

import {
    completeFinalExpenseCurrentStep,
    enableAllFinalExpenseSteps,
    previousFinalExpenseStep,
    resetStepperState,
    setGuidedProcess,
    skipFinalExpenseCurrentStep
} from 'components/ConsumerGuidedExperienceModal/slice';
import Modal from 'components/Modal';
import ModalWindow from 'components/ModalWindow';
import { finalExpensePlanType } from 'components/PlanTypeModal/constants';
import { setHealthConditionContentModal } from 'components/YourHealthConditionContentModal/slice';
import YourHealthInfoModalContent from 'components/YourHealthInfoModalContent';
import { setLocationModal } from 'components/YourLocationModal/slice';
import useFetch from 'components/hooks/useFetch';

import dataLayer from 'utilities/dataLayer';
import { CONSUMER_SERVICE_URL } from 'utilities/env';
import { FINAL_EXPENSE_KEY } from 'utilities/storageKeys';

import image1 from './image1.svg';
import image from './image.svg';

import {
    headerTitle,
    headerText,
    saveButtonLabel,
    headerText1,
    continueButtonLabel
} from './constants';
import { setHealthInfoData, setHealthInfoModal } from './slice';

import styles from './styles.module.scss';

import { get, set } from 'temp/utilities/storage';

const YourHealthInfoModal = () => {
    const contactDetailsPageRef = useRef(null);
    const [finalExpenseDetails] = useState(get(FINAL_EXPENSE_KEY));
    const [gender, setGender] = useState(finalExpenseDetails?.gender);
    const [tobacco, setTobacco] = useState(finalExpenseDetails?.tobaccoUse);
    const [heightFeet, setHeightFeet] = useState(
        finalExpenseDetails?.heightInFT
    );
    const [dateOfBirth, setDateOfBirth] = useState(
        finalExpenseDetails?.dateOfBirth
    );
    const [heightInch, setHeightInch] = useState(finalExpenseDetails?.heightIn);
    const [weightVal, setWeightVal] = useState(finalExpenseDetails?.weight);
    const [dateOfBirthError, setDateOfBirthError] = useState(false);
    const [heightInchError, setHeightInchError] = useState(false);
    const [heightFeetError, setHeightFeetError] = useState(false);
    const [weightValError, setWeightValError] = useState(false);
    const [isDisable, setIsDisable] = useState(false);
    const healthInfoModal = useSelector(
        state => state.healthInfoDetails?.healthInfoModal
    );
    const isGuided = useSelector(state => state.guidedDetails.isGuidedProcess);

    useEffect(() => {
        if (healthInfoModal) {
            dataLayer('modal_appear', headerTitle);
        }
    }, [healthInfoModal]);
    const dispatch = useDispatch();
    const userProfile = useSelector(state => state.userProfile.userProfile);

    const { isAuthenticated, user } = useAuth0();
    const { Put: updateAccountData } = useFetch(
        `${import.meta.env.VITE_APP_ACCOUNT_SERVICE_URL}/Update`
    );
    const { Get: getConsumerByConsumerId } = useFetch(
        `${CONSUMER_SERVICE_URL}/${user?.consumerid}`
    );
    const onClose = async isContinue => {
        if (isContinue && contactDetailsPageRef.current.isValidDetails()) {
            localStorage.setItem('HealthInfoDataChanged', true);
            let payload = {};
            payload.gender = gender;
            payload.heightInFT = heightFeet;
            payload.heightIn = heightInch;
            payload.weight = weightVal;
            payload.tobaccoUse = tobacco;
            payload.dateOfBirth = dateOfBirth;
            payload.zipCode = finalExpenseDetails?.zipCode;
            payload.county = finalExpenseDetails?.county;
            payload.productType = finalExpensePlanType;
            set(FINAL_EXPENSE_KEY, payload);
            if (isAuthenticated && user.consumerid) {
                const heightInCMT =
                    Number(heightFeet * 12) + Number(heightInch);
                await updateAccountData({
                    ...userProfile,
                    height: heightInCMT,
                    weight: weightVal || null,
                    dateOfBirth: new Date(dateOfBirth),
                    isTobaccoUser: tobacco === 'Yes' ? true : false,
                    gender: gender,
                    productType: finalExpensePlanType
                });
                const response = await getConsumerByConsumerId().catch(error =>
                    console.error(error)
                );

                dispatch(
                    setUserProfile({
                        ...response
                    })
                );
            }
            dispatch(setHealthInfoModal(false));
            dispatch(setHealthInfoData(payload));
            isGuided && dispatch(setHealthConditionContentModal(true));
            isGuided && dispatch(completeFinalExpenseCurrentStep());
            isGuided && dispatch(enableAllFinalExpenseSteps());
        } else if (!isContinue) {
            dispatch(setHealthInfoModal(false));
            dispatch(setGuidedProcess(false));
            isGuided && dispatch(resetStepperState());
        }
    };

    useEffect(() => {
        const isValid = () => {
            if (
                gender?.trim() === '' ||
                tobacco?.trim() === '' ||
                dateOfBirth?.trim() === '' ||
                dateOfBirthError ||
                heightFeetError ||
                heightInchError ||
                weightValError
            )
                return false;

            return true;
        };
        const state = isValid();
        setIsDisable(!state);
    }, [
        dateOfBirth,
        dateOfBirthError,
        gender,
        heightFeetError,
        heightInchError,
        tobacco,
        weightValError
    ]);

    const handleBackButton = () => {
        dispatch(setLocationModal(true));
        dispatch(setHealthInfoModal(false));
        dispatch(previousFinalExpenseStep());
    };

    const skipButtonClicked = () => {
        dispatch(setHealthInfoModal(false));
        dispatch(setHealthConditionContentModal(true));
        dispatch(skipFinalExpenseCurrentStep());
    };

    const isBack = useMemo(() => {
        const fromPage = localStorage.getItem('healthConditionFromPage');
        return isGuided ? (fromPage ? false : true) : false;
    }, [isGuided]);

    return (
        <div className={styles.modalWithHeaderFooter}>
            <Modal className={styles.modal} isOpen={healthInfoModal}>
                <ModalWindow
                    closeIconClassName={styles.closeIconClassName}
                    contentClassName={styles.contentClassName}
                    footerButtonClassName={
                        isGuided
                            ? styles.footerButton
                            : styles.footerButtonClassName
                    }
                    footerButtonClicked={() => onClose(true)}
                    footerButtonDisabled={isDisable}
                    footerClassName={styles.footerClassName}
                    footerLabel={
                        isGuided ? continueButtonLabel : saveButtonLabel
                    }
                    headerClassName={styles.headerClassName}
                    headerIcon={isGuided ? image1 : image}
                    headerText={isGuided ? headerText1 : headerText}
                    headerTitle={headerTitle}
                    modalWindow={styles.modalWindow}
                    onClose={() => onClose()}
                    backButtonClicked={handleBackButton}
                    isBack={isBack}
                    isSkip={false}
                    skipButtonClicked={skipButtonClicked}
                >
                    <div className={styles.content}>
                        <YourHealthInfoModalContent
                            setDateOfBirthVal={setDateOfBirth}
                            setGender={setGender}
                            setHeightFeet={setHeightFeet}
                            setTobacco={setTobacco}
                            setHeightInch={setHeightInch}
                            setWeightVal={setWeightVal}
                            setDateOfBirthError={setDateOfBirthError}
                            setHeightInchError={setHeightInchError}
                            setHeightFeetError={setHeightFeetError}
                            setWeightValError={setWeightValError}
                            isModal={true}
                            ref={contactDetailsPageRef}
                        />
                    </div>
                </ModalWindow>
            </Modal>
        </div>
    );
};

export default memo(YourHealthInfoModal);
